import React from "react";

import { Container } from "react-bootstrap";

import Page from "components/Page/Page";

const config = [
  {
    name: "Videos & Image Heading",
    moduleComponentName: "SectionHeadingWithButtons",
    rowProps: {
      className: "py-5 bg-primary text-light",
      noGutters: true,
    },
    moduleProps: {
      sectionHeadingProps: {
        heading: {
          priority: 2,
          style: {
            fontWeight: "bold",
          },
          text: "Images and videos",
        },
        subheading: {
          text: `View project images and videos.`,
        },
      },
      buttons: [
        {
          text: "Artist's Impressions",
          props: {
            variant: "light",
            href: "#artists-impressions",
          },
        },
        {
          text: "Project Expert Videos",
          props: {
            variant: "light",
            href: "#project-expert-videos",
          },
        },
        {
          text: "Videos",
          props: {
            variant: "light",
            href: "#videos",
          },
        },
      ],
    },
    wrapWithContainer: true,
  },
  {
    name: "Artist's Impressions",
    moduleComponentName: "GalleryGroup",
    rowProps: {
      className: "mt-3",
      noGutters: true,
    },
    moduleProps: {
      groupHeadingName: "Artist's Impressions",
      groupHeadingId: "artists-impressions",
      style: {
        scrollMarginTop: "100px",
      },
      gallery: {
        thumbnailProps: [
          {
            columnProps: { xs: 12, sm: 6, md: 4, className: "my-2" },
            fileName: "artists-impressions-1",
            hoverText: "St Marys Metro Station",
            startingLightboxIndex: 0,
          },
          {
            columnProps: { xs: 12, sm: 6, md: 4, className: "my-2" },
            fileName: "artists-impressions-2",
            hoverText: "Orchard Hills Station",
            startingLightboxIndex: 1,
          },
          {
            columnProps: { xs: 12, sm: 6, md: 4, className: "my-2" },
            fileName: "artists-impressions-3",
            hoverText: "Luddenham Station",
            startingLightboxIndex: 2,
          },
          {
            columnProps: { xs: 12, sm: 6, md: 4, className: "my-2" },
            fileName: "artists-impressions-4",
            hoverText: "Airport Business Park Station",
            startingLightboxIndex: 3,
          },
          {
            columnProps: { xs: 12, sm: 6, md: 4, className: "my-2" },
            fileName: "artists-impressions-5",
            hoverText: "Airport Terminal Station",
            startingLightboxIndex: 4,
          },
          {
            columnProps: { xs: 12, sm: 6, md: 4, className: "my-2" },
            fileName: "artists-impressions-6",
            hoverText: "Airport Terminal Station - internal view",
            startingLightboxIndex: 5,
          },
          {
            columnProps: { xs: 12, sm: 6, md: 4, className: "my-2" },
            fileName: "artists-impressions-7",
            hoverText: "Aerotropolis Station",
            startingLightboxIndex: 6,
          },
          {
            columnProps: { xs: 12, sm: 6, md: 4, className: "my-2" },
            fileName: "artists-impressions-8",
            hoverText: "Metro train",
            startingLightboxIndex: 7,
          },
        ],
        lightboxSources: [
          {
            type: "image",
            title: "Artists impressions 1",
            fileName: "artists-impressions-1",
          },
          {
            type: "image",
            title: "Artists impressions 2",
            fileName: "artists-impressions-2",
          },
          {
            type: "image",
            title: "Artists impressions 3",
            fileName: "artists-impressions-3",
          },
          {
            type: "image",
            title: "Artists impressions 4",
            fileName: "artists-impressions-4",
          },
          {
            type: "image",
            title: "Artists impressions 5",
            fileName: "artists-impressions-5",
          },
          {
            type: "image",
            title: "Artists impressions 6",
            fileName: "artists-impressions-6",
          },
          {
            type: "image",
            title: "Artists impressions 7",
            fileName: "artists-impressions-7",
          },
          {
            type: "image",
            title: "Artists impressions 8",
            fileName: "artists-impressions-8",
          },
        ],
      },
    },
    wrapWithContainer: true,
  },
  {
    name: "Project Expert Videos",
    moduleComponentName: "GalleryGroup",
    rowProps: {
      className: "mt-3",
      noGutters: true,
    },
    moduleProps: {
      groupHeadingName: "Project Expert Videos",
      groupHeadingId: "project-expert-videos",
      style: {
        scrollMarginTop: "100px",
      },
      gallery: {
        thumbnailProps: [
          {
            columnProps: { xs: 12, sm: 6, md: 4, className: "my-2" },
            fileName: "video-customers-and-placemaking",
            isForVideo: true,
            startingLightboxIndex: 0,
          },
          {
            columnProps: { xs: 12, sm: 6, md: 4, className: "my-2" },
            fileName: "video-delivering-a-new-metro",
            isForVideo: true,
            startingLightboxIndex: 1,
          },
          {
            columnProps: { xs: 12, sm: 6, md: 4, className: "my-2" },
            fileName: "video-minimising-local-impacts",
            isForVideo: true,
            startingLightboxIndex: 2,
          },
          {
            columnProps: { xs: 12, sm: 6, md: 4, className: "my-2" },
            fileName: "video-planning-for-tomorrow",
            isForVideo: true,
            startingLightboxIndex: 3,
          },
          {
            columnProps: { xs: 12, sm: 6, md: 4, className: "my-2" },
            fileName: "video-project-overview",
            isForVideo: true,
            startingLightboxIndex: 4,
          },
          {
            columnProps: { xs: 12, sm: 6, md: 4, className: "my-2" },
            fileName: "video-transport-and-traffic",
            isForVideo: true,
            startingLightboxIndex: 5,
          },
          {
            columnProps: { xs: 12, sm: 6, md: 4, className: "my-2" },
            fileName: "video-tunnelling-and-surface-works",
            isForVideo: true,
            startingLightboxIndex: 6,
          },
          {
            columnProps: { xs: 12, sm: 6, md: 4, className: "my-2" },
            fileName: "video-working-with-the-community",
            isForVideo: true,
            startingLightboxIndex: 7,
          },
        ],
        lightboxSources: [
          {
            type: "video",
            title: "Customers and placemaking",
            src: "https://player.vimeo.com/video/469591282?app_id=122963",
          },
          {
            type: "video",
            title: "Delivering a New Metro",
            src: "https://player.vimeo.com/video/470015498?app_id=122963",
          },
          {
            type: "video",
            title: "Minimising local impacts",
            src: "https://player.vimeo.com/video/469614220?app_id=122963",
          },
          {
            type: "video",
            title: "Planning for tomorrow",
            src: "https://player.vimeo.com/video/469589743?app_id=122963",
          },
          {
            type: "video",
            title: "Project Overview",
            src: "https://player.vimeo.com/video/470032265?app_id=122963",
          },
          {
            type: "video",
            title: "Transport and traffic",
            src: "https://player.vimeo.com/video/469593171?app_id=122963",
          },
          {
            type: "video",
            title: "Tunnelling and Surface Works",
            src: "https://player.vimeo.com/video/469664234?app_id=122963",
          },
          {
            type: "video",
            title: "Working with the community",
            src: "https://player.vimeo.com/video/469596926?app_id=122963",
          },
        ],
      },
    },
    wrapWithContainer: true,
  },
  {
    name: "Videos",
    moduleComponentName: "GalleryGroup",
    rowProps: {
      className: "mt-3",
      noGutters: true,
    },
    moduleProps: {
      groupHeadingName: "Videos",
      groupHeadingId: "videos",
      style: {
        scrollMarginTop: "100px",
      },
      gallery: {
        thumbnailProps: [
          {
            columnProps: { xs: 12, sm: 6, md: 4, className: "my-2" },
            fileName: "artists-impressions-1",
            hoverText: "St Marys Metro Station animation",
            isForVideo: true,
            startingLightboxIndex: 0,
          },
          {
            columnProps: { xs: 12, sm: 6, md: 4, className: "my-2" },
            fileName: "artists-impressions-3",
            hoverText: "Luddenham Station animation",
            isForVideo: true,
            startingLightboxIndex: 1,
          },
        ],
        lightboxSources: [
          {
            type: "video",
            title: "St Marys Metro Station",
            src: "https://player.vimeo.com/video/469651977?app_id=122963",
          },
          {
            type: "video",
            title: "Luddenham Station",
            src: "https://player.vimeo.com/video/469652460?app_id=122963",
          },
        ],
      },
    },
    wrapWithContainer: true,
  },
];

const VideoImagePage = () => {
  return (
    <Container fluid className="page-view">
      <Page config={config} />
    </Container>
  );
};

export default VideoImagePage;
